import React from "react"
import { Link } from "gatsby"
import Emoji from "./emoji"

const SubNavLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default function SubNavigation({ currentPageName, pages, isMenuOpen, title }) {
  return (
    <div className="sub-navigation">
      <div className="sub-navigation--desktop">
        {title && (
          <div className="sub-navigation-title">
            <div className="sub-navigation-title--text">{title.title}</div>
            <Emoji className="sub-navigation-title--emoji" symbol={title.symbol} label={title.symbolLabel}/>
          </div>
        )}
        {pages.map((page, index) => (
          (page.name === currentPageName) ? (
            <div className="sub-navigation-current" key={index}>{page.shortName || page.name}</div>
          ) : (
            <SubNavLink to={page.path} key={index}>
              {page.shortName || page.name}
            </SubNavLink>
          )
        )    
        )}
     </div>
        <div className={`navigation--mobile${isMenuOpen ? ' is-active' : ''}`}>
        {title && (
          <div className="sub-navigation-title">
            <div className="sub-navigation-title--text">{title.title}</div>
            <Emoji className="sub-navigation-title--emoji" symbol={title.symbol} label={title.symbolLabel}/>
          </div>
        )}
        {pages.map((page, index) => (
        (page.name === currentPageName) ? (
          <div className="sub-navigation-current" key={index}>{page.shortName || page.name}</div>
        ) : (
          <SubNavLink to={page.path} key={index}>
            {page.shortName || page.name}
          </SubNavLink>
        )
      )    
     )}
        </div>
    </div>
  );
}

/*

        <div
          className={`navigation-hamburger${isMenuOpen ? ' is-active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)} >
            <div className="hamburger-box">
              <div className="hamburger-inner">

              </div>
            </div>
        </div>
        <div className={`navigation--mobile${isMenuOpen ? ' is-active' : ''}`}>
          <NavLink to="/about/">About</NavLink>
          <NavLink to="/faq/">FAQ</NavLink>
          <NavLink to="/contact/">Contact</NavLink>
        </div>
        */