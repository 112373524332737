import React from "react"
import { Link } from "gatsby"

const ExternalLink = props => (
  <li>
    <a target="_blank" rel="noopener noreferrer" href={props.to}>{props.children}</a>
  </li>
)

const NavLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default function Footer({ blog }) {
  const home = blog ? 'https://blog.refresh.systems/' : '/';
  const about = blog ? 'https://blog.refresh.systems/about/' : '/about/';
  const faq = blog ? 'https://blog.refresh.systems/faq/' : '/faq/';
  const contact = blog ? 'https://blog.refresh.systems/contact/' : '/contact/';
  return (
    <div className="footer">
      <ul className="footer-links">
        <div className="footer-links-group">
          <div className="footer-links-title">Learn more</div>
          <NavLink to={home}>Home</NavLink>
          <NavLink to={about}>About us</NavLink>
          <NavLink to={faq}>FAQ</NavLink>
          <NavLink to={contact}>Contact us</NavLink>
        </div>
        <div className="footer-links-group">
          <div className="footer-links-title">Donate</div>
          <ExternalLink to="https://www.buymeacoffee.com/refresh1">Buy me a coffee</ExternalLink>
          <ExternalLink to="https://www.patreon.com/refresh1">Donate via Patreon</ExternalLink>
          <ExternalLink to="https://www.paypal.com/paypalme/adorepayments">Donate via PayPal</ExternalLink>
        </div>
      </ul>
      <div className="footer-details">
        <div className="footer-legal">
          As an Amazon Associate I earn from qualifying purchases.
<br></br><br></br>
          We may earn a commission when you use one of our links to make a purchase.
        </div>
        <div className="footer-copyright">
          Copyright © 2020, Adore LLC
        </div>
      </div>
    </div>
  )
}