import React from "react"
import { Helmet } from "react-helmet"
import Navigation from "./navigation"
import Footer from "./footer"
import "../styles/index.scss"

export default function Layout({ relatedPages, name, children, showBurger, title, blog }) {
  return (
    <div className="layout">
      <Helmet>
        <title>Refresh</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-180760738-1"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-180760738-1');
          `}
        </script>
      </Helmet>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MFQTMJH"
height="0" width="0" style={{visibility:"hidden", display:"none"}}></iframe></noscript>
      <div className="layout-body">
        <Navigation relatedPages={relatedPages} name={name} showBurger={showBurger} title={title} blog={blog}/>
        {children}
        <Footer blog={blog}/>
      </div>
    </div>
  )
}