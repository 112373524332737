import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../images/refresh-logo.png";
import SubNavigation from "./subNavigation";



/*const NavLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)
*/


export default function Navigation({ relatedPages, name, showBurger, title, blog }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const joinUrl = 'https://discord.gg/xb4BFqb';

  const home = blog ? 'https://blog.refresh.systems' : '/';

  return (
    <>
    <div className="navigation">
      <header>
        <ul className="navigation--bar">
          <div className={`navigation-hamburger${isMenuOpen ? ' is-active' : ''} ${showBurger ? '' : 'hidden'}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)} >
              <div className="hamburger-box">
                <div className="hamburger-inner">
                </div>
              </div>
          </div>
          <Link to={home} className="navigation-title">
            <img src={logo} alt="Refresh Shopping Cart Logo" />
            <h2>Refresh</h2>
          </Link>
          {!blog && <a className="navigation-cta" target="_blank" rel="noopener noreferrer" href={joinUrl}>
            <div>Join</div>
            <div className="hidden-mobile">Our Community</div>
          </a>}
        </ul>
      </header>
    </div>
    {relatedPages && 
      <SubNavigation currentPageName={name} pages={relatedPages} isMenuOpen={isMenuOpen} title={title}/>
    }
    </>
  )
}
/*
export default function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="navigation">
      <header>
        <ul className="navigation--bar">
          <Link to="/" className="navigation-title">
            <img src={logo} alt="Refresh Shopping Cart Logo" />
            <h2>Refresh</h2>
          </Link>
          <NavLink to="/about/">About</NavLink>
          <NavLink to="/faq/">FAQ</NavLink>
          <NavLink to="/contact/">Contact</NavLink>
        </ul>
        <div
          className={`navigation-hamburger${isMenuOpen ? ' is-active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)} >
            <div className="hamburger-box">
              <div className="hamburger-inner">

              </div>
            </div>
        </div>
        <div className={`navigation--mobile${isMenuOpen ? ' is-active' : ''}`}>
          <NavLink to="/about/">About</NavLink>
          <NavLink to="/faq/">FAQ</NavLink>
          <NavLink to="/contact/">Contact</NavLink>
        </div>
      </header>
    </div>
  )
}*/